import React from "react";
import styles from "./App.module.scss";

// App component.
function App() {
  // Render.
  return (
    <div className={styles.App}>
      <h1>Softwarenerd</h1>
      <div>This is the website of Brian Lambert.</div>
    </div>
  );
}

// Export App component.
export default App;
